<template>
	<div class="p-5 text-gray-400">
		<div class="bg-gray-800 p-3 flex items-center rounded-sm">
			<div class="rounded-full border-2 border-gray-700 p-2">
				<i class="icon-user icon-3x"></i>
			</div>
			<div class="ml-3">
				<div>PT INDOPACIFIC</div>
				<div>---</div>
			</div>
			<!-- <div class="ml-auto mr-2">
				<a @click="modalPengaturan=true" href="#">
					<i class="icon-cog3"></i>
				</a>
			</div> -->
		</div>
		<div class="mt-5 bg-gray-800 rounded-sm p-3">
			<!-- <div>NAMA PERUSAHAAN</div>
			<div>Alamat</div>
			<div>kontak</div> -->
		</div>
		<div class="mt-5">
			<button @click="logOut" class="bg-green-700 w-full py-2 rounded-sm"><i class="icon-switch mr-2 mb-1"></i> Keluar</button>
		</div>
		<modal :show="modalPengaturan">
			<div class="min-h-screen w-full bg-gray-800">
				<div class="flex items-center p-2">
                    <button @click="modalPengaturan=false" class="p-2"><i class="icon-cross2 pb-px"></i></button>
                    <div class="text-lg">Pengaturan akun</div>
                </div>
				<div class="mt-5 p-5">
					<div class="">
						<div>INFO PENGGUNA</div>
						<form action="">
							<div class="mt-5">
								<label for="" class="text-xs">Nama Lengkap</label>
								<input type="text" class="h-9 bg-gray-800 border-b border-gray-700 w-full rounded-sm text-lg text-gray-500 focus:outline-none" value="SATCOMM">
							</div>
							<div class="mt-5">
								<label for="" class="text-xs">Email</label>
								<input type="email" class="h-9 bg-gray-800 border-b border-gray-700 w-full rounded-sm text-lg text-gray-500 focus:outline-none" value="ihasatcomm@gmail.com">
							</div>
							<div class="mt-5">
								<button class="bg-green-700 w-full py-2 rounded-sm">SIMPAN</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Modal from '../components/Popup.vue'
export default {
	components: {
		Modal
	},
	data () {
		return {
			modalPengaturan: false,
		}
	},
	methods: {
		logOut () {
			localStorage.removeItem('token')
			this.$router.push('/login')
		}
	}
}
</script>