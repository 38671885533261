<template>
	<div class="text-gray-400">
		<div class="fixed bg-gray-800 max-w-lg w-full z-10 flex items-center pl-3">
			<div class="text-gray-400">PT INDOPACIFIC</div>
			<router-link to="/notif" class="ml-auto mr-1 py-3 p-3 text-gray-400">
				<div class="relative">
					<i class="icon-bell2"></i>
					<div v-if="unread" class="absolute text-gray-300 rounded-full -top-2 -right-2 w-4 h-4 min-w-min p-1 bg-red-700 flex items-center justify-center">
						<div style="font-size: 10px">{{unread}}</div>
					</div>
				</div>
			</router-link>
		</div>
		<div class="px-3 pt-16">
			<div class="bg-gradient-to-r from-gray-800 to-gray-700  p-3 rounded-sm mb-4">
				<div class="grid grid-cols-2 gap-5 mb-2">
					<div class="col-span-1">
						<div class="text-md font-semibold">DEBIT</div>
						<div class="flex items-center justify-between text-center">
							<div>
								<div class="text-gray-500 text-xs">Rata-rata</div>
								<div class="text-green-600">{{parseFloat(avg.debit).toFixed(2)}}</div>
							</div>
							<div>
								<div class="text-gray-500 text-xs">Puncak</div>
								<div class="text-red-600">{{parseFloat(max.debit).toFixed(2)}}</div>
							</div>
						</div>
					</div>
					<div class="col-span-1 border rounded-sm flex items-center border-cyan-500 bg-cyan-300 bg-opacity-10 shadow-md">
						<div class="pl-3">
							<svg class="text-cyan-500 opacity-30" xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" viewBox="0 0 16 16"><path d="M13.5 0a.5.5 0 0 0 0 1H15v2.75h-.5a.5.5 0 0 0 0 1h.5V7.5h-1.5a.5.5 0 0 0 0 1H15v2.75h-.5a.5.5 0 0 0 0 1h.5V15h-1.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5h-2zM7 1.5l.364-.343a.5.5 0 0 0-.728 0l-.002.002-.006.007-.022.023-.08.088a28.458 28.458 0 0 0-1.274 1.517c-.769.983-1.714 2.325-2.385 3.727C2.368 7.564 2 8.682 2 9.733 2 12.614 4.212 15 7 15s5-2.386 5-5.267c0-1.05-.368-2.169-.867-3.212-.671-1.402-1.616-2.744-2.385-3.727a28.458 28.458 0 0 0-1.354-1.605l-.022-.023-.006-.007-.002-.001L7 1.5zm0 0-.364-.343L7 1.5zm-.016.766L7 2.247l.016.019c.24.274.572.667.944 1.144.611.781 1.32 1.776 1.901 2.827H4.14c.58-1.051 1.29-2.046 1.9-2.827.373-.477.706-.87.945-1.144zM3 9.733c0-.755.244-1.612.638-2.496h6.724c.395.884.638 1.741.638 2.496C11 12.117 9.182 14 7 14s-4-1.883-4-4.267z"></path></svg>
						</div>
						<div class="text-right w-full mr-2">
							<transition name="flash" mode="out-in">
								<div :key="modbus.debit" class="text-xl font-semibold text-cyan-500">{{modbus.debit}}</div>
							</transition>
							<div class="leading-none text-xs">m3/menit</div>
						</div>
					</div>
				</div>
				<div class="relative" style="height:98px">
					<canvas id="debit"></canvas>
				</div>
			</div>
			
			<div class="grid grid-cols-2 gap-4">
				<!-- PH METER -->
				<div class="bg-gradient-to-r from-gray-800 to-gray-700 p-3 rounded-md mb-4">
					<div class="flex items-center mb-1">
						<div>
							<i class="icon-droplet icon-3x text-gray-500 opacity-30"></i>
						</div>
						<div class="ml-auto text-right">
							<transition name="flash" mode="out-in">
								<h1 :key="modbus.ph" class="text-xl font-medium text-green-600">{{modbus.ph}}</h1>
							</transition>
							<div>pH Meter</div>
						</div>
					</div>
					<div class="flex items-center justify-between text-center">
						<div>
							<div class="text-gray-500 text-xs">Rata-rata</div>
							<div class="text-green-600 text-xs">{{parseFloat(avg.ph).toFixed(2)}}</div>
						</div>
						<div>
							<div class="text-gray-500 text-xs">Puncak</div>
							<div class="text-red-600">{{parseFloat(max.ph).toFixed(2)}}</div>
						</div>
					</div>
				</div>
				<!-- /end ph meter -->

				<!-- Temperature -->
				<div class="bg-gradient-to-r from-gray-800 to-gray-700 p-3 rounded-md mb-4">
					<div class="flex items-center mb-1">
						<div>
							<svg class="text-gray-500 opacity-30" xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" viewBox="0 0 16 16"><path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z"></path><path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z"></path></svg>
						</div>
						<div class="ml-auto text-right">
							<transition name="flash" mode="out-in">
								<h1 :key="modbus.tmp" class="text-xl font-medium text-red-600">{{modbus.tmp}} ℃</h1>
							</transition>
							<div>Temperatur</div>
						</div>
					</div>
					<div class="flex items-center justify-between text-center">
						<div>
							<div class="text-gray-500 text-xs">Rata-rata</div>
							<div class="text-green-600 text-xs">{{parseFloat(avg.tmp).toFixed(2)}}</div>
						</div>
						<div>
							<div class="text-gray-500">Puncak</div>
							<div class="text-red-600">{{parseFloat(max.tmp).toFixed(2)}}</div>
						</div>
					</div>
				</div>
				<!-- /end temperature -->
			</div>
			<!-- COD -->
			<div class="bg-gradient-to-r from-gray-800 to-gray-700  p-3 rounded-sm mb-4">
				<div class="grid grid-cols-2 gap-10">
					<div class="col-span-1">
						<div class="text-md font-semibold">COD</div>
						<div class="flex items-center justify-between text-center">
							<div>
								<div class="text-gray-500 text-xs">Rata-rata</div>
								<div class="text-green-600">{{parseFloat(avg.cod).toFixed(2)}}</div>
							</div>
							<div>
								<div class="text-gray-500 text-xs">Puncak</div>
								<div class="text-red-600">{{parseFloat(max.cod).toFixed(2)}}</div>
							</div>
						</div>
					</div>
					<div class="col-span-1 border rounded-sm flex items-center border-yellow-500 bg-yellow-300 bg-opacity-10 shadow-md">
						<div class="pl-3">
							<i class="icon-lab icon-2x opacity-30 text-yellow-500"></i>
						</div>
						<div class="text-right w-full mr-2">
							<transition name="flash" mode="out-in">
								<div :key="modbus.cod" class="text-xl font-semibold text-yellow-500">{{modbus.cod}}</div>
							</transition>
							<div class="leading-none text-xs">mg/L</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /end cod -->
			<!-- TSS -->
			<div class="bg-gradient-to-r from-gray-800 to-gray-700  p-3 rounded-sm mb-4">
				<div class="grid grid-cols-2 gap-10">
					<div class="col-span-1">
						<div class="text-md font-semibold">TSS</div>
						<div class="flex items-center justify-between text-center">
							<div>
								<div class="text-gray-500 text-xs">Rata-rata</div>
								<div class="text-green-600">{{parseFloat(avg.tss).toFixed(2)}}</div>
							</div>
							<div>
								<div class="text-gray-500 text-xs">Puncak</div>
								<div class="text-red-600">{{parseFloat(max.tss).toFixed(2)}}</div>
							</div>
						</div>
					</div>
					<div class="col-span-1 border rounded-sm flex items-center border-purple-500 bg-purple-300 bg-opacity-10 shadow-md">
						<div class="pl-3">
							<i class="icon-droplets icon-2x opacity-30 text-purple-500"></i>
						</div>
						<div class="text-right w-full mr-2">
							<transition name="flash" mode="out-in">
								<div :key="modbus.tss" class="text-xl font-semibold text-purple-500">{{modbus.tss}}</div>
							</transition>
							<div class="leading-none text-xs">mg/L</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /end tss -->
			<!-- NH4N -->
			<div class="bg-gradient-to-r from-gray-800 to-gray-700  p-3 rounded-sm mb-20">
				<div class="grid grid-cols-2 gap-10">
					<div class="col-span-1">
						<div class="text-md font-semibold">NH3N</div>
						<div class="flex items-center justify-between text-center">
							<div>
								<div class="text-gray-500 text-xs">Rata-rata</div>
								<div class="text-green-600">{{parseFloat(avg.nh3n).toFixed(2)}}</div>
							</div>
							<div>
								<div class="text-gray-500 text-xs">Puncak</div>
								<div class="text-red-600">{{parseFloat(max.nh3n).toFixed(2)}}</div>
							</div>
						</div>
					</div>
					<div class="col-span-1 border rounded-sm flex items-center border-blue-500 bg-blue-300 bg-opacity-10 shadow-md">
						<div class="pl-3">
							<i class="icon-atom2 icon-2x opacity-30 text-blue-500"></i>
						</div>
						<div class="text-right w-full mr-2">
							<transition name="flash" mode="out-in">
								<div :key="modbus.nh3n" class="text-xl font-semibold text-blue-500">{{modbus.nh3n}}</div>
							</transition>
							<div class="leading-none text-xs">mg/L</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /end nh4n -->
		</div>
		
	</div>
</template>

<script>
import Chart from 'chart.js/auto'
import { db } from '../db'
import axios from 'axios'
const sound = require('@/assets/notif.mp3')
export default {
	data () {
		return {
			value: 5,
			modbus: '',
			debit: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			debitTime: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			avg: {
				ph: 0,
				tmp: 0,
				cod: 0,
				tss: 0,
				nh3n: 0,
				debit: 0
			},
			max: {
				ph: 0,
				tmp: 0,
				cod: 0,
				tss: 0,
				nh3n: 0,
				debit: 0
			},
			notif: '',
			unread: ''
		}
	},
	firebase: {
		modbus: db.ref('iha/indopacific/modbus'),
		notif: db.ref('iha/indopacific/notif')
	},	
	computed: {
		token () {
			return localStorage.getItem('token')
		},
	},
	mounted () {
		this.getData()
		let app = this
		var ctxDebit = document.getElementById('debit')
		window.myDebit = new Chart(ctxDebit, {
			type: 'line',
			data: {
				labels: app.debit,
				datasets: [{
					data: app.debit,
					borderColor: 'rgb(5,182,212)',
					backgroundColor: 'rgb(5,182,212, 0.2)',
					tension: 0.4,
					fill: true
				}]
			},
			options: {
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					}
				},
				scales: {
					x: {
						labels: false,
						ticks: {
							color: 'rgb(239,68,68)',
							display: false
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(55,65,81)',
							display: true
						}
					}
				}
			}
		})
	},
	methods: {
		getData () {
			axios.get('/dekstop')
			.then((res) => {
				this.avg = res.data.avg
				this.max = res.data.max
			})
		},
		getNotif () {
			this.unread = localStorage.getItem('notif')
			axios.get('/notifications')
			.then((res) => {
				this.unread = res.data.count
				if(res.data.count > localStorage.getItem('notif')) {
					let notif = new Audio(sound)
					notif.play()
				}
				localStorage.setItem('notif', res.data.count)
			})
		}
	},
	watch: {
		notif () {
			if(this.token) {
				this.getNotif()
			}
		},
		'modbus.debit': function (val ) {
			let time = new Date(this.modbus.timestamp * 1000)

			if(this.debit.length > 17) {
				this.debit.splice(0, 1)
			}
			if(this.debitTime.length > 17) {
				this.debitTime.splice(0,1)
			}
			this.debit.push(val)
			this.debitTime.push(`${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
			window.myDebit.update()
		}
	}
}
</script>
<style>
.flash-enter-active {
	animation: flash;
}
.flash-leave-active {
	animation: flash 0.3s;
}
.flash-enter, .flash-leave-to {
	opacity: 0;
}
@keyframes flash {
            0%, 50%, 100% {
               opacity: 1;
            }
            25%, 75% {
               opacity: 0;
            }
         }
</style>